import '../../styles/pages/spring-summer-2021/vanishing-words.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import MoreStories from "../../components/spring-summer-2021-stories/more-stories"
import Modal from 'react-modal';
import Vimeo from '@u-wave/react-vimeo';
import Icon from "../../components/icons";
import ReactFullpage from '@fullpage/react-fullpage';
import GoogleMapReact from 'google-map-react';
import { Timeline, Tween } from 'react-gsap';
import { Preloader, Placeholder } from 'react-preloading-screen';
import { SwishSpinner } from "react-spinners-kit";
// import TextPDF from '../../components/spring-summer-2021-stories/text-pdf';
import { withPrefix } from "gatsby"

import closeIcon from '../../images/icons/close.svg';
import socialBanner from "../../images/spring-summer-2021/social-banners/vanishing-words.jpg";

import timothyHenryRodriguez from '../../images/spring-summer-2021/vanishing-words/timothy-henry-rodriguez.jpg'
import franzMueller from '../../images/spring-summer-2021/vanishing-words/franz-mueller.jpg'
import patriciaSchneiderZioga from '../../images/spring-summer-2021/vanishing-words/patricia-schneider-zioga.jpg'
import kennethVanBik from '../../images/spring-summer-2021/vanishing-words/kenneth-van-bik.jpg'

import homeIcon from '../../images/spring-summer-2021/vanishing-words/home-icon.svg'
import mapMarkerIcon from '../../images/spring-summer-2021/vanishing-words/map-marker-icon.svg'


Modal.setAppElement('#___gatsby')

const pluginWrapper = () => {
  require('../../scripts/fullpage.responsiveSlides.min.js');
  require('../../scripts/fullpage.scrollHorizontally.min.js');
};

function createMapOptions(maps) {
  return {
    disableDefaultUI: true,
    mapTypeId: 'satellite',
    scrollwheel: false
  };
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      progress: 0,
      playState: 'play',
      pauseButtonText: 'Pause Text',
      introButtonText: 'Skip Text Intro',
      animateClass: '',
      headerHide: false,
      lat: 35.191262003136245,
      lng: -120.79940522868377
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.pause = this.pause.bind(this);
    this.skip = this.skip.bind(this);
    this.complete = this.complete.bind(this);
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  pause() {
    if (this.state.playState === 'play') {
      this.setState({playState: 'pause'});
      this.setState({pauseButtonText: 'Resume Text'});
      // this.videoTeaser.current.pause();
    } else if (this.state.playState === 'pause') {
      this.setState({playState: 'play'});
      this.setState({pauseButtonText: 'Pause Text'});
      // this.videoTeaser.current.play();
    }
  }

  skip() {
    if (this.state.progress < 1) {
      this.setState({progress: 1});
      this.setState({introButtonText: 'Replay Text Intro'});
      this.setState({animateClass: 'vanish'});
    } else {
      this.setState({progress: 0});
      this.setState({introButtonText: 'Skip Intro'});
    }
  }

  complete() {
    this.setState({progress: 1});
    this.setState({introButtonText: 'Replay Text Intro'});
    this.setState({animateClass: 'vanish'});
  }

  mapTour() {

    this.setState({
      lat: -3.0265885212261474,
      lng: 121.59700074682264
    })

    setTimeout(function() {
      this.setState({
        lat: -2.875450877029716,
        lng: 23.52375977874027
      })
    }.bind(this), 2500)

    setTimeout(function() {
      this.setState({
        lat: -13.676225752583022,
        lng: -172.45263440207827
      })
    }.bind(this), 4500)

    setTimeout(function() {
      this.setState({
        lat: 21.4077410211248,

        lng: 95.91384374149068
      })
    }.bind(this), 6500)

    setTimeout(function() {
      this.setState({
        lat: 35.191262003136245,
        lng: -120.79940522868377
      })
    }.bind(this), 7500)


  }


  render() {
    var pageTitle = 'Vanishing Words';
    var pageDesc = 'Preserving Languages Near Extinction';
    var slug = 'vanishing-words';


    return (
      <Layout headerStyle="white" headerHide={this.state.headerHide} locationInfo={this.props.location} issue="spring-summer-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>

        <Preloader fadeDuration={500} style={{backgroundColor: '#111'}}>

        

        <ReactFullpage
          id="story-content"
          licenseKey={'74BC1699-BD29452E-B9391E28-1CBD2120'}
          // menu="#menu"
          pluginWrapper={pluginWrapper}
          // loopBottom={true}
          normalScrollElements={'#mainNav'}
          navigation={false}
          slidesNavigation={false}
          controlArrows={false}
          responsiveSlides={true}
          responsiveSlidesKey={'ZnVsbGVydG9uLmVkdV85MzFjbVZ6Y0c5dWMybDJaVk5zYVdSbGN3PT00Zkk='}
          responsiveWidth={'769'}
          scrollHorizontally={true}
          scrollHorizontallyKey={'ZnVsbGVydG9uLmVkdV9iQVFjMk55YjJ4c1NHOXlhWHB2Ym5SaGJHeDUwdFc='}
          continuousHorizontal={true}
          scrollOverflow={true}
          onLeave ={(origin, destination, direction) => {

            if (direction === 'down') {
              this.setState({headerHide: true});
            } else {
              this.setState({headerHide: false});
            }

          }}
          afterLoad ={(origin, destination, direction) => {

            if(destination.anchor === 'map') {
              this.mapTour();
            }

          }}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <div className="section hero-section fp-noscroll" data-anchor="home">

                <a href={ withPrefix('/pdf/spring-summer-2021/vanishing-words.pdf') } id="pdf-text" target="_blank">
                  <Icon name="pdf" alt="PDF Icon" />
                  Download text alternative PDF
                </a>

                  <Timeline
                    progress={this.state.progress}
                    playState={this.state.playState}
                    onComplete={this.complete}
                    target={
                      <div className="hero-text">
                        <blockquote>
                          <p>“Every language, endangered or otherwise, is an independent reflection of how a particular group of people has conceptualized the world and the human condition over the course of its history.”</p>
                          <cite>- Kenneth Van Bik, CSUF assistant professor of linguistics</cite>
                        </blockquote>
                      </div>
                    }
                  >
                    <Tween from={{ opacity: 0, y: '20px' }} to={{ opacity: 1, y: '0px' }} duration={3} />
                    <Tween to={{ opacity: 1 }} duration={5} />
                    <Tween to={{ opacity: 0 }} duration={1} />

                      <Timeline
                        target={
                          <div className="hero-text">
                            <p>Colonization and the passing of generations are threatening the disappearance of global languages.</p>
                          </div>
                        }
                      >
                        <Tween from={{ opacity: 0, y: '20px' }} to={{ opacity: 1, y: '0px' }} duration={3} />
                        <Tween to={{ opacity: 1 }} duration={5} />
                        <Tween to={{ opacity: 0 }} duration={1} />

                        <Timeline
                          target={
                            <div className="hero-text">
                              <p>With more than half of the world’s 6,500 languages headed toward extinction, Cal State Fullerton linguistics researchers are working to document them before they are forever lost.</p>
                            </div>
                          }
                        >
                          <Tween from={{ opacity: 0, y: '20px' }} to={{ opacity: 1, y: '0px' }} duration={3} />
                          <Tween to={{ opacity: 1 }} duration={5} />
                          <Tween to={{ opacity: 0 }} duration={1} />

                          <Timeline
                            target={
                              <div className="hero-text title-slate">
                                <h1 className="story-title center"><span className={this.state.animateClass}>Vanishing</span>Words</h1>
                                <span className="sub-title center">Preserving Languages Near Extinction</span>
                                <span className="byline">Story by Lynn Juliano, Video by Matt Gush</span>
                                <div className="center" style={{marginTop: '50px'}}>
                                  <button className="button" onClick={this.openModal}>Watch Video <Icon name="arrow-right" alt="Right Arrow" className="sm" /></button>
                                  <button className="button small" onClick={() => fullpageApi.moveSectionDown()}>More <Icon name="arrow-down" alt="Down Arrow" className="sm" /></button>
                                </div>
                              </div>
                            }
                          >
                            <Tween from={{ opacity: 0, y: '20px' }} to={{ opacity: 1, y: '0px' }} duration={3} />

                          </Timeline>

                        </Timeline>

                      </Timeline>

                  </Timeline>

                      

                  <div className="animation-controls">
                    <button onClick={this.pause} title="">{this.state.pauseButtonText}</button>
                    <button onClick={this.skip} title="">{this.state.introButtonText}</button>
                  </div>
                  <video id="heroVideo" data-autoplay autoPlay="autoplay" loop muted playsInline>
                    <source src="https://news.fullerton.edu/_resources/titanmag/video/spring-summer-2021/vanishing-words-loop.webm" type="video/webm; codecs=vp9,vorbis" />
                    <source src="https://news.fullerton.edu/_resources/titanmag/video/spring-summer-2021/vanishing-words-loop.mp4" type="video/mp4" />
                    <track kind="captions" srcLang="en" label="English" src="https://news.fullerton.edu/_resources/titanmag/video/spring-summer-2021/vanishing-words-loop.vtt" />
                  </video>
                </div>
                <div className="section fp-auto-height-responsive fp-noscroll" data-anchor="map" style={{backgroundColor: 'black'}}>
                  <div className="map-text">
                    <h2>Research Around the World</h2>
                    <p>From Africa and Asia to Oceania and North America, Cal State Fullerton linguistics faculty members are working to document and preserve several of the world’s endangered languages.</p>
                    <p><small><em><Icon name="information" alt="Information" className="sm" /> Use your mouse to move the map and explore different regions.</em></small></p>
                  </div>
                  <div className="map-wrapper">
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: 'AIzaSyDIi62RXw6bBtRI7L5EONNUAGPsGlZVMpw' }}
                      defaultCenter={{ lat: 35.191262003136245, lng: -120.79940522868377}}
                      center={{ lat: this.state.lat, lng: this.state.lng}}
                      defaultZoom={1}
                      options={createMapOptions}
                      // onChildMouseEnter={this.onChildClickCallback}
                    >
                      <div
                        className="map-marker"
                        lat={35.191262003136245}
                        lng={-120.79940522868377}
                      >
                        <span className="map-marker-number">1</span>
                        <button className="map-marker-info" onClick={() => fullpageApi.moveTo('timothy-henry-rodriguez')} title="Learn more about Timothy Henry-Rodriguez">
                          <span className="name">Timothy Henry-Rodriguez</span>
                          <span className="lang">Purisimeño, Ventureño (Chumashan languages)</span>
                        </button>
                      </div>
                      <div
                        className="map-marker"
                        lat={-3.0265885212261474}
                        lng={121.59700074682264}
                      >
                        <span className="map-marker-number">2</span>
                        <button className="map-marker-info" onClick={() => fullpageApi.moveTo('franz-mueller')} title="Learn more about Franz Mueller">
                          <span className="name">Franz Mueller</span>
                          <span className="lang">Sundanese</span>
                        </button>
                      </div>
                      <div
                        className="map-marker"
                        lat={-2.875450877029716}
                        lng={23.52375977874027}
                      >
                        <span className="map-marker-number">3</span>
                        <button className="map-marker-info" onClick={() => fullpageApi.moveTo('patricia-schneider-zioga')} title="Learn more about Patricia Schneider-Zioga">
                          <span className="name">Patricia Schneider-Zioga</span>
                          <span className="lang">Kinande (Bantu language)</span>
                        </button>
                      </div>
                      <div
                        className="map-marker"
                        lat={-13.676225752583022}
                        lng={-172.45263440207827}
                      >
                        <span className="map-marker-number">4</span>
                        <button className="map-marker-info" onClick={() => fullpageApi.moveTo('franz-mueller-oceania')} title="Learn more about Franz Mueller">
                          <span className="name">Franz Mueller</span>
                          <span className="lang">Oceania</span>
                        </button>
                      </div>
                      <div
                        className="map-marker"
                        lat={21.4077410211248}
                        lng={95.91384374149068}
                      >
                        <span className="map-marker-number">5</span>
                        <button className="map-marker-info" onClick={() => fullpageApi.moveTo('kenneth-van-bik')} title="Learn more about Kenneth Van Bik">
                          <span className="name">Kenneth Van Bik</span>
                          <span className="lang">Lamtuk-Ruavan, Mkang, Tapong, Taungtha</span>
                        </button>
                      </div>
  
                    </GoogleMapReact>
                  </div>
                  <div className="map-text-cta">
                    <p className="learn-more">Support the College of Humanities and Social Sciences<br /><a href="https://campaign.fullerton.edu/hss" target="_blank" rel="noopener noreferrer">campaign.fullerton.edu/hss</a></p>
                  </div>
                </div>
                <div className="section fp-auto-height-responsive expert timothy-henry-rodriguez ca-coast" data-anchor="timothy-henry-rodriguez">
                  <div className="text wrap small">
                    <ul className="slide-nav">
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('home')} title="Go to story homepage"><img src={homeIcon} alt="Home" /></button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('map')} title="Go to map"><img src={mapMarkerIcon} alt="Map" /></button></li>
                      <li><button className="slide-nav-button active" onClick={() => fullpageApi.moveTo('timothy-henry-rodriguez')} title="Timothy Henry-Rodriguez">1</button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('franz-mueller')} title="Franz Mueller">2</button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('patricia-schneider-zioga')} title="Patricia Schneider-Zioga">3</button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('franz-mueller-oceania')} title="Franz Mueller (Oceania)">4</button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('kenneth-van-bik')} title="Kenneth Van Bik">5</button></li>
                    </ul>
                    <div className="text-hero-image" />
                    
                    <img src={timothyHenryRodriguez} alt="Timothy Henry-Rodriguez" className="photo" />
                    <h3>Timothy Henry-Rodriguez</h3>
                    <span className="title">Lecturer in linguistics</span>
                    <hr />

                    <p>
                      <strong>Languages studied:</strong> Purisimeño, Ventureño (Chumashan languages)<br />
                      <strong>Region:</strong> U.S., Central Coast of California<br />
                      <strong>Status of language:</strong> Extinct<br />
                      <strong>Funding source:</strong> National Endowment for the Humanities
                    </p>

                    <p><strong>What does your research entail?</strong> My current research project aims to produce a grammatical description and a lexicon of the Purisimeño language. A lexicon is similar to a dictionary, but it typically includes fewer entries and less analysis because there is less data to work with. My methodology involves reading and analyzing the Purisimeño linguistic notes of John Peabody Harrington and other scholars who recorded the language in the late 19th and early 20th centuries.</p>

                    <p><strong>How did the languages become endangered?</strong> Purisimeño and other Chumashan languages became endangered during the period of Spanish missionization in the late 18th and early 19th centuries. Missionization included forced relocations, conversion to Catholicism, and stigmatization of their languages and cultures. Disease also reduced Indigenous population numbers.</p>

                    <p><strong>Why is it important to study such languages?</strong> For a language to move out of an endangered or more severe status, it needs to gain more native speakers than it loses. It will be difficult for Purisimeño to move out of its current status given the relatively small amount of data on the language, but certainly some fluency in the language could be gained if one had access to a grammar and a lexicon.</p>

                    <p><strong>One interesting fact about the languages:</strong> Purisimeño and other Chumashan languages are quite different from many of the largest modern languages. The languages have an extensive number of consonants, including a type of consonant known as an ejective. This is written with an apostrophe over a consonant symbol: p̓, t̓, k̓, q̓, s̓, š̓, ts̓, tš̓. The languages also prefer to put the verb at the beginning of a sentence, which differs from many languages in Europe and Asia that put the verb in the middle of the sentence (English, French, German, Mandarin) or at the end of the sentence (Korean, Mongolian, Japanese).</p>
                  </div>
                </div>
                <div className="section fp-auto-height-responsive expert franz-mueller indonesia" data-anchor="franz-mueller">
                  <div className="text wrap small">
                    <ul className="slide-nav">
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('home')} title="Go to story homepage"><img src={homeIcon} alt="Home" /></button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('map')} title="Go to map"><img src={mapMarkerIcon} alt="Map" /></button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('timothy-henry-rodriguez')} title="Timothy Henry-Rodriguez">1</button></li>
                      <li><button className="slide-nav-button active" onClick={() => fullpageApi.moveTo('franz-mueller')} title="Franz Mueller">2</button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('patricia-schneider-zioga')} title="Patricia Schneider-Zioga">3</button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('franz-mueller-oceania')} title="Franz Mueller (Oceania)">4</button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('kenneth-van-bik')} title="Kenneth Van Bik">5</button></li>
                    </ul>
                    <div className="text-hero-image" />
                    
                    <img src={franzMueller} alt="Franz Mueller" className="photo" />
                    <h3>Franz Mueller</h3>
                    <span className="title">Professor of linguistics</span>
                    <hr />

                    <p>
                      <strong>Languages studied:</strong> Sundanese<br />
                      <strong>Region:</strong> Indonesia<br />
                      <strong>Status of language:</strong> Diminishing<br />
                      <strong>Funding source:</strong> Intramural grants

                    </p>

                    <p><strong>What does your research entail?</strong> I work with native speakers of the languages with the goal of describing and documenting their languages.</p>

                    <p><strong>How did the language become endangered?</strong> Sundanese is suffering what I call “functional attrition,” which means that it is used in fewer and fewer areas of life as speakers move to the national language, Indonesian.</p>

                    <p><strong>Why is it important to study such languages?</strong> Each language — in its vocabulary, its grammar and in its unique conventions of using language — represents a unique creation and a unique way of understanding the world. If a language dies before it has been properly documented, this understanding is irretrievably lost.</p>

                    <p><strong>One interesting fact about the language:</strong> Sundanese used to be more widely spoken throughout the western half of the island of Java, but it was gradually replaced in the central areas of the island by the Javanese language of east and central Java, as well as by the growth of the Malay-speaking area of Jakarta, the capital city of Indonesia.</p>
                  </div>
                </div>
                <div className="section fp-auto-height-responsive expert patricia-schneider-zioga congo" data-anchor="patricia-schneider-zioga">
                  <div className="text wrap small">
                    <ul className="slide-nav">
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('home')} title="Go to story homepage"><img src={homeIcon} alt="Home" /></button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('map')} title="Go to map"><img src={mapMarkerIcon} alt="Map" /></button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('timothy-henry-rodriguez')} title="Timothy Henry-Rodriguez">1</button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('franz-mueller')} title="Franz Mueller">2</button></li>
                      <li><button className="slide-nav-button active" onClick={() => fullpageApi.moveTo('patricia-schneider-zioga')} title="Patricia Schneider-Zioga">3</button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('franz-mueller-oceania')} title="Franz Mueller (Oceania)">4</button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('kenneth-van-bik')} title="Kenneth Van Bik">5</button></li>
                    </ul>
                    <div className="text-hero-image" />
                    
                    <img src={patriciaSchneiderZioga} alt="Patricia Schneider-Zioga" className="photo" />
                    <h3>Patricia Schneider-Zioga</h3>
                    <span className="title">Associate professor of linguistics</span>
                    <hr />

                    <p>
                      <strong>Languages studied:</strong> Kinande (Bantu language)<br />
                      <strong>Region:</strong> Democratic Republic of the Congo<br />
                      <strong>Status of language:</strong> Diminishing<br />
                      <strong>Funding source:</strong> College of Humanities and Social Sciences research and writing stipends
                    </p>

                    <p><strong>What does your research entail?</strong> I am working with Philip Ngessimo Mutaka — native speaker, linguist and visiting scholar from the University of Yaounde 1 in Cameroon — to write a grammar of Kinande, which belongs to the Bantu family of languages. Mutaka is documenting the sound system and socio-linguistic aspects of the language, and I am documenting the syntax and morphology (word structure) of the language. We plan to make our grammar available to all readers through open access.</p>

                    <p><strong>How did the language become endangered?</strong> Kinande is a regional language in a post-colonial rule. Since the DRC was colonized by Belgium, school children are taught almost exclusively in French. This puts them at a major educational disadvantage from the beginning of their school career as they do not natively speak the language of the educational system. Kinande is spoken by about one million people, but will almost certainly become endangered because it has no official writing system and is not taught in schools in the DRC. </p>

                    <p><strong>Why is it important to study such languages?</strong> The cultural and cognitive knowledge contained in Kinande is important to document as it is a rich and valuable system of knowledge, and contributes to our understanding of how language works in the human mind. It also can be used to develop educational materials related to Kinande and adds to our appreciation of the culture of the Nande people.</p>

                    <p>
                      <strong>One interesting fact about the language:</strong> Kinande has the longest word of any Bantu language as far as we know (it’s a sentence in English, but a single word in Kinande):<br /> 
                      tunémundisyátásyayabakinguliranisiákyô <br />
                      “We will make it possible one more time for them to open it for each other.”
                    </p>
                  </div>
                </div>
                <div className="section fp-auto-height-responsive expert franz-mueller-oceania oceania" data-anchor="franz-mueller-oceania">
                  <div className="text wrap small">
                    <ul className="slide-nav">
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('home')} title="Go to story homepage"><img src={homeIcon} alt="Home" /></button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('map')} title="Go to map"><img src={mapMarkerIcon} alt="Map" /></button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('timothy-henry-rodriguez')} title="Timothy Henry-Rodriguez">1</button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('franz-mueller')} title="Franz Mueller">2</button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('patricia-schneider-zioga')} title="Patricia Schneider-Zioga">3</button></li>
                      <li><button className="slide-nav-button active" onClick={() => fullpageApi.moveTo('franz-mueller-oceania')} title="Franz Mueller (Oceania)">4</button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('kenneth-van-bik')} title="Kenneth Van Bik">5</button></li>
                    </ul>
                    <div className="text-hero-image" />
                    
                    <img src={franzMueller} alt="Franz Mueller" className="photo" />
                    <h3>Franz Mueller</h3>
                    <span className="title">Professor of linguistics</span>
                    <hr />

                    <p>
                      <strong>Languages studied:</strong> Samoan<br />
                      <strong>Region:</strong> Oceania<br />
                      <strong>Status of language:</strong> Diminishing<br />
                      <strong>Funding source:</strong> Intramural grants

                    </p>

                    <p><strong>What does your research entail?</strong> I work with native speakers of the languages with the goal of describing and documenting their languages.</p>

                    <p><strong>How did the language become endangered?</strong> Samoan is still healthy in Samoa itself but has been increasingly displaced by English, especially in American Samoa.</p>

                    <p><strong>Why is it important to study such languages?</strong> Each language — in its vocabulary, its grammar and in its unique conventions of using language — represents a unique creation and a unique way of understanding the world. If a language dies before it has been properly documented, this understanding is irretrievably lost.</p>

                    <p><strong>One interesting fact about the language:</strong> There is a sizable Samoan community in Orange County, around Garden Grove.</p>

                  </div>
                </div>
                <div className="section fp-auto-height-responsive expert kenneth-van-bik burma" data-anchor="kenneth-van-bik">
                  <div className="text wrap small">
                    <ul className="slide-nav">
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('home')} title="Go to story homepage"><img src={homeIcon} alt="Home" /></button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('map')} title="Go to map"><img src={mapMarkerIcon} alt="Map" /></button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('timothy-henry-rodriguez')} title="Timothy Henry-Rodriguez">1</button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('franz-mueller')} title="Franz Mueller">2</button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('patricia-schneider-zioga')} title="Patricia Schneider-Zioga">3</button></li>
                      <li><button className="slide-nav-button" onClick={() => fullpageApi.moveTo('franz-mueller-oceania')} title="Franz Mueller (Oceania)">4</button></li>
                      <li><button className="slide-nav-button active" onClick={() => fullpageApi.moveTo('kenneth-van-bik')} title="Kenneth Van Bik">5</button></li>
                    </ul>
                    <div className="text-hero-image" />
                    
                    <img src={kennethVanBik} alt="Kenneth Van Bik" className="photo" />
                    <h3>Kenneth Van Bik</h3>
                    <span className="title">Assistant professor of linguistics</span>
                    <hr />

                    <p>
                      <strong>Languages studied:</strong> Lamtuk-Ruavan, Mkang, Tapong, Taungtha<br />
                      <strong>Region:</strong> Chin State, Myanmar (Burma)<br />
                      <strong>Status of language:</strong> Endangered<br />
                      <strong>Funding source:</strong> National Science Foundation

                    </p>

                    <p><strong>What does your research entail?</strong> Traveling to the research sites in Chin State, Myanmar; working with members of language communities to document the languages; presenting the research findings in conferences; and writing peer-reviewed articles based on the research findings.  </p>

                    <p><strong>How/why did the languages become endangered?</strong> First, their population dwindled due to migration. And, most significantly, they face the social and political pressure from the local and national dominant languages. </p>

                    <p><strong>Why is it important to study such languages?</strong> Every language, endangered or otherwise, is an independent reflection of how a particular group of people has conceptualized the world and the human condition over the course of its history. The study and documentation of such unique knowledge encoded in their languages is a very important mission of linguistic research.</p> 

                    <p><strong>One interesting fact about the languages:</strong> The speakers of these languages are multilingual speakers. For example, a Lamtuk speaker is conversational in Sakta (a neighboring Lai dialect), Hakha Lai (the dominant local language) and Burmese (the national language of Myanmar).</p>

                  </div>
                </div>
                <div className="section fp-auto-height">
                  <MoreStories slug={slug} />
                </div>
              </ReactFullpage.Wrapper>
            );
          }}
        />

        <Modal
          closeTimeoutMS={400}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          contentLabel="Watch Video"
          className="video-modal"
          overlayClassName="overlay"
          shouldCloseOnOverlayClick={true}
        >
          <button onClick={this.closeModal} id="closeButton"><img src={closeIcon} alt="" /> Close</button>
          <Vimeo
            video={537933937}
            responsive
            showTitle={false}
            showByline={false}
            showPortrait={false}
            autoplay
            onEnd={this.closeModal}
          />
        </Modal>

        <Placeholder>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <SwishSpinner size={30} frontColor="#dc8218" backColor="#00aeff" />
            <span style={{
              fontSize: '.7em',
              display: 'block', 
              marginTop: '30px', 
              color: '#ccc', 
              textTransform: 'uppercase',
              textAlign: 'center',
              letterSpacing: '5px'
            }}>
              Loading
            </span>
          </div>
				</Placeholder>
			</Preloader>

      </Layout>
    );
  }
}